export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"deploy-version","content":"v4.30.4.tgz"},{"name":"deploy-date","content":"Tue, 08 Apr 2025 10:00:41 GMT"}],"link":[{"rel":"icon","href":"https://marketplace-assets.s3.eu-west-3.amazonaws.com/2023/07/cropped-favicon-32x32.png","sizes":"32x32"},{"rel":"icon","href":"https://www.spalopia.com/wp-content/uploads/fbrfg/favicon.ico","sizes":"48x48"},{"rel":"icon","href":"https://marketplace-assets.s3.eu-west-3.amazonaws.com/2023/07/cropped-favicon-192x192.png","sizes":"192x192"},{"rel":"apple-touch-icon-precomposed","href":"https://marketplace-assets.s3.eu-west-3.amazonaws.com/2023/07/cropped-favicon-180x180.png","sizes":"180x180"}],"style":[],"script":[{"defer":true,"type":"text/javascript","src":"https://cdn.lr-in-prod.com/LogRocket.min.js","skip":false},{"defer":true,"type":"text/javascript","src":"/scripts/fullstory.js","skip":false}],"noscript":[],"title":"Spalopia"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true